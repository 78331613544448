import "core-js/modules/es.number.constructor.js";
import moment from "moment";
export function useNow() {
  var second = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
  var now = ref(moment());
  var timer;
  onMounted(function () {
    timer = setInterval(function () {
      now.value = moment();
    }, 1000 * second);
  });
  onUnmounted(function () {
    clearInterval(timer);
  });

  var getToNowMin = function getToNowMin(time) {
    return Number(now.value.diff(moment(time), 'minutes'));
  };

  return {
    getToNowMin: getToNowMin
  };
}